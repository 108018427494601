import React, { Component } from 'react'
import Message from '../messages/Message'
import {sendmail} from '../services.js'

export default class MessageForm extends Component {
    state={
        data:{
            name: '',
            email:'',
            contactno:'',
            message:''
        },
        loading:false,
        error:{}
    }

    onChange=(e)=>{
        this.setState({
            data:{...this.state.data,[e.target.name]:e.target.value}
        })
    }

    onSubmit= async (e)=>
    {
        e.preventDefault()
        const error = this.validate(this.state.data)
        this.setState({ error:error });
        if (Object.keys(error).length===0)
        {
			const name = this.state.data.name
			const email = this.state.data.email
			const contactno = this.state.data.contactno
			const message = this.state.data.message
			
			await sendmail(name, email, contactno, message)
			this.setState({
                data:{name:"",email:"",contactno:"",message:""}
			})
        }
    }

    validate = (data)=>{
        const error = {};
        if (!data.name) error.name="Name Must be required"
        if (!data.email) error.email="Email Must be required"
        if (!data.contactno) error.contactno="Contact No. Must be required"
        if (!data.message) error.message="Message Must be required"
        return error;
    }
	
    render()
    {
        const {data,error} = this.state;
        return (
            <form  onSubmit={this.onSubmit} >
                <div className='from-group'>
                    <input type='text' name='name' id='name' placeholder='Name*' value={data.name} onChange={this.onChange} className='form-control'/>
                </div>
                <Message message={error.name}/>
                <div className='from-group'>
                    <input type='email' name='email' id='email' placeholder='Email*' value={data.email} onChange={this.onChange} className='form-control'/>
                </div>
                <Message message={error.email}/>
                <div className='from-group'>
                    <input type='contactno' name='contactno' id='contactno' placeholder='Contact No.*' value={data.contactno} onChange={this.onChange} className='form-control'/>
                </div>
                <Message message={error.contactno}/>
                <div className='from-group'>
                    <textarea rows="8" className='form-control' id = 'message' name='message' placeholder='Message*' value={data.message} onChange={this.onChange}></textarea>
                </div>
                <Message message={error.message}/>
                <input type='submit' name= 'submit' value = 'Send' style={{ backgroundColor : "black" , color : "white" , borderRadius : "32px", padding : "16px", width : "100%" }} />
                {/* <div className='mb-3' style={{backgroundColor : "black" , color : 'white' , textAlign : "center" , borderRadius : "32px" , padding : "16px"}} >
                    
                </div> */}
            </form>
        )
    }
}
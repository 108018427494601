import React, { Component ,Fragment} from 'react'
import MessageForm from '../form/MessageForm'
import softwaredevelopment from '../images/development.jpg'
import softwaretesting from '../images/testing.jpg'
import professionalservices from '../images/services.jpg'

export default class SingleHomePage extends Component {
    render() {
        return (
            <Fragment>
                 <section className='section_client'>
                    <div className='container text-center py-5'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-5 col-12 text-white h1'>
                                DigiA2S Technologies Private Limited
                            </div>
                        </div>
                        <div className='row justify-content-center mt-5'>
                            <div className='col-lg-8 col-12 text-white display-5' >
                            Product, Solutions and Professional Services
                            </div>
                        </div>
                        <div className='row justify-content-center mt-3'>
                            <div className='col-lg-5 col-12 text-white' style={{fontSize : "24px"}} >
                            Bring your ideas to digital world
                            </div>
                        </div>
                        <div className='row justify-content-center mt-5'>
                            <div className='col-lg-5 col-12' >
                            <a href='#contactUs' style={{backgroundColor : "white" , textDecoration : "none" , color : "black" , borderRadius : "32px", padding: "16px 32px" , border : "0"}}>GET IN TOUCH</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='container-fluid'>
                        <div className='row pl-3' >
                            <div className='col-lg-6 col-12 order-lg-1 order-2 '>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-12 mt-3 h1' style={{color : "#817d7d"}} >
                                            About Us
                                        </div>
                                        <div className='col-12 mt-3' style={{fontSize : "20px"}}>
                                            We are an IT Software Product & Services organization that assist our clients in their digital transformation journey to achieve strategic goal.
                                        </div>
                                        <div className='col-12 mt-3' style={{fontSize : "20px"}}>
                                            We work with our clients as business partner and help to transform their business ideas into reality through digitization. Client satisfaction and user experience is utmost priority in our organization. Below are the key services offered: 
                                        </div>
                                        <div className='col-12 mt-3'>
                                            <ul>
                                                <li>Enterprise Application Development</li>
                                                <li>Mobile & Web Engineering</li>
                                                <li>BPM and ECM based implementations on various licensed and free platforms.</li>
                                                <li>Different solutions catering to business need focused in Banking, Financial Services and Insurance sector</li>
                                                <li>CRM implementation for various industries</li>
                                                <li>Generative AI focusing on Lending space.</li>
                                                <li>Aerospace Engineering</li>
                                                <li>Testing and Quality Assurance</li>
                                                <li>Consulting & Best Practices Workshops</li>
                                                <li>Application Maintenance & Support</li>
                                                <li>Data Migration</li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className='section1 col-lg-6 col-12 order-lg-2 order-1'>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <section style={{backgroundColor : "#80808033"}}>
                    <div className='container text-center'>
                        <div className='row'>
                            <div className='col-12 h1 mt-5 text-center'>Our Service</div>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-lg-4 col-md-6 col-12 mt-4'>
                                <div className="card" style = {{overflow : 'hidden' , border : '0' , backgroundColor : "inherit"}}>
                                    <img src={softwaredevelopment} className="card-img-top mx-3" alt="backgroud" style={{borderRadius : "100%" , width : "300px" , height : "300px"  }} />
                                    <div className="card-body">
                                        <h5 className="card-title h5">Software Development</h5>
                                        <p className="card-text mt-3">At DigiA2S, we turn the digital dreams of our clients into a reality. We work closely with our clients and business users throughout SDLC to ensure that we are aligned with the end goals. We follow client centric approach and committed to producing exceptional software for each of our clients catering their business needs.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-12 mt-4'>
                                <div className="card" style = {{overflow : 'hidden' , border : '0' ,  backgroundColor : "inherit"}}>
                                    <img src={softwaretesting} className="card-img-top mx-3" alt="backgroud" style={{borderRadius : "100%" , width : "300px" , height : "300px"}} />
                                    <div className="card-body">
                                        <h5 className="card-title h5">Testing And Quality Assurance</h5>
                                        <p className="card-text mt-3">At DigiA2S, we closely work with clients and partner in SDLC to perform testing and provide quality assurance. These tests are done using various industry standard tools and manually. Post testing, test cases along with test results, reports are shared to our clients.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-12 mt-4'>
                                <div className="card" style = {{overflow : 'hidden' , border : '0' , backgroundColor : "inherit"}}>
                                    <img src={professionalservices} className="card-img-top mx-3" alt="backgroud" style={{borderRadius : "100%" , width : "300px" , height : "300px"}} />
                                    <div className="card-body">
                                        <h5 className="card-title h5">Consultancy and Services</h5>
                                        <p className="card-text mt-3">Today clients want a business partner who can not only develop and implement software's  but also suggest the industry best practices.  We pride ourselves on providing professional services, consulting services such as best practices across various industries, workshop to study and identify business needs, RFP, RFI preparation and participation.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="contactUs">
                    <div className = 'container'>
                        <div className = 'row justify-content-center'>
                            <div className='col-12 text-center h1 mt-5'>Contact Us</div>
                            <div className='col-8 mt-5'>
                                <MessageForm/>
                            </div>
                            
                        </div>
                    </div>
                </section>
                <section className='mt-5' style={{backgroundColor : "grey" , color : "white" , padding : "40px"}}>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                            Copyright © 2024 DigiA2S Technologies Private Limited - All Rights Reserved.
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
}
